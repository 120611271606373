import React from "react";

import checkIcon from "../../assets/images/check.svg";

import styles from "./AuthOption.module.css";

export const AuthOption = props => {
  const { title, subtitle, isSelected, onSelect } = props;
  return (
    <div className={styles.authOption}>
      <button
        type="button"
        className={`${styles.optionButton} ${isSelected && styles.optionButtonSelected}`}
        onClick={() => onSelect()}
      >
        {isSelected && <img src={checkIcon} alt="check" />}
      </button>
      <span className={styles.title} onClick={() => onSelect()}>
        {title}
        <span className={styles.subtitle}>{subtitle}</span>
      </span>
    </div>
  );
};

import React, { SyntheticEvent, useState } from "react";

import { sendPasswordResetRequest } from "../api/auth";
import icon_envelope from "../assets/images/icon_envelope.svg";

import queryString from "query-string";
import styles from "./AuthForm.module.css";
import btn from "./controls/Button.module.css";
import fg from "./controls/FormGroup.module.css";
import txt from "./controls/Texts.module.css";

function validateEmail(email: string) {
  // prettier-ignore
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const PasswordResetForm = () => {
  //fields
  const [email, setEmail] = useState("");

  const [linkSent, setLinkSent] = useState(false);

  function extractRedirectUrl() {
    const parsedQueryString = queryString.parse(window.location.search);
    return parsedQueryString.redirect_url as string;
  }
  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    sendPasswordResetRequest(email, extractRedirectUrl()).then(r => setLinkSent(true));
  }

  return (
    <>
      {!linkSent && (
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <h2 className={txt.title}>Forgot your password?</h2>
          <span className={txt.paragraph}>
            Nothing to worry about. We will send a unique link to your inbox. Follow the link to
            create yourself a new shiny password.
          </span>
          <div className={fg.formGroup}>
            <input
              id="email"
              autoFocus
              type="email"
              placeholder="email"
              autoComplete="username"
              value={email}
              className={fg.formInput}
              onChange={e => setEmail(e.target.value)}
            />
            <label className={fg.formLabel} htmlFor="email">
              E-mail
            </label>
          </div>

          <button disabled={!validateEmail(email)} type="submit" className={btn.submitButton}>
            reset password
          </button>
          <a href={"/login"}>
            <span className={styles.backToLogin}>Back to log in</span>
          </a>
        </form>
      )}
      {linkSent && (
        <section>
          <img src={icon_envelope} alt="envelope icon" className={styles.envelopeImg} />
          <h2 className={txt.title}>
            If your email address is valid, you’ll receive an email with a link to reset your
            password shortly.
          </h2>
          <h3>What's next</h3>
          <span>Check your inbox and use link to set a new password.</span>
        </section>
      )}
    </>
  );
};

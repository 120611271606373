import React, { useRef } from "react";
import styled from "styled-components";

import { ClearInputValueButton } from "./ClearInputValueButton";
import { Label } from "./Label";
import { inputFontBase, inputWrapperBase } from "./styles";
import { InputProps } from "./types";

const LabeledInputWrapper = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input<{ $hasErrors: boolean; disabled: boolean }>`
  ${inputWrapperBase}
  ${inputFontBase}
  height: 36px;
  width: 100%;
  padding: 0 36px 0 12px;
`;

export const TextInput = (props: InputProps) => {
  const {
    className,
    clearable,
    disabled = false,
    error,
    id,
    label,
    labelVisible = true,
    required,
    onBlur,
    onChange,
    value,
    ...other
  } = props;
  const ref = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e.currentTarget.value);
  };

  const clear = () => {
    onChange(undefined);
    setTimeout(() => ref?.current?.focus());
  };

  return (
    <LabeledInputWrapper className={className}>
      <Label inputId={id} label={label} required={required} visible={labelVisible} />
      <InputWrapper>
        <StyledInput
          {...other}
          ref={ref}
          id={id}
          type="text"
          disabled={disabled}
          value={value || ""}
          aria-invalid={!!error}
          aria-errormessage={`${other.name}-error-message`}
          $hasErrors={!!error}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {clearable && value !== undefined && value.length > 0 && !disabled && (
          <ClearInputValueButton title="Clear" onClick={clear} />
        )}
      </InputWrapper>
    </LabeledInputWrapper>
  );
};

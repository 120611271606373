import Axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";

import queryString from "query-string";
import { sendPasswordSetRequest } from "../api/auth";

import { isVndError } from "../lib/error";
import styles from "./AuthForm.module.css";
import btn from "./controls/Button.module.css";
import fg from "./controls/FormGroup.module.css";
import txt from "./controls/Texts.module.css";
import { ErrorMessage } from "./styled/ErrorMessage";

function validatePassword(pass1: string, pass2: string) {
  // FIXME: add proper password strength validation
  return pass1.length > 0 && pass2.length > 0 && pass1 === pass2;
}

export const PasswordSetForm = () => {
  //fields
  const [token, setToken] = useState("");
  const [invitation, setInvitation] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");

  const [linkSent, setLinkSent] = useState(false);

  function handleSubmit(event: SyntheticEvent) {
    setError("");
    event.preventDefault();
    sendPasswordSetRequest(token, invitation, newPassword)
      .then(r => setLinkSent(true))
      .catch((err: unknown) => {
        if (
          Axios.isAxiosError(err) &&
          isVndError(err.response?.data) &&
          err.response?.status === 422
        ) {
          setError(err.response.data.message);
        }
        throw err;
      });
  }

  function hasToken() {
    return token || invitation;
  }

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search);
    setToken(parsedQueryString.token as string);
    setInvitation(parsedQueryString.invitation as string);
    setRedirectUrl(parsedQueryString.redirect_url as string);
  }, []);

  return (
    <>
      {!hasToken() && !linkSent && (
        <div>
          <h2 className={txt.title}>Token parameter not found.</h2>
          <span className={txt.paragraph}>Please copy the whole URL address from the email.</span>
        </div>
      )}

      {hasToken() && !linkSent && (
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <h2 className={txt.title}>Set a new password</h2>
          <span className={txt.paragraph}>Type in a new password and you’re ready to go!</span>
          <div className={fg.formGroup}>
            <input
              id="password"
              autoFocus
              type="password"
              placeholder="password"
              autoComplete="new-password"
              value={newPassword}
              className={fg.formInput}
              onChange={e => setNewPassword(e.target.value)}
            />
            <label className={fg.formLabel} htmlFor="password">
              New Password
            </label>
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div className={fg.formGroup}>
            <input
              id="password"
              type="password"
              placeholder="password"
              autoComplete="new-password"
              value={repeatPassword}
              className={fg.formInput}
              onChange={e => setRepeatPassword(e.target.value)}
            />
            <label className={fg.formLabel} htmlFor="password">
              Repeat Password
            </label>
          </div>

          <button
            disabled={!validatePassword(newPassword, repeatPassword)}
            type="submit"
            className={btn.submitButton}
          >
            reset password
          </button>
        </form>
      )}
      {linkSent && (
        <div>
          <h2 className={txt.title}>The new password has been set.</h2>
          <a href={`/login?redirect_url=${redirectUrl}`}>
            <span className={styles.backToLogin}>Back to log in</span>
          </a>
        </div>
      )}
    </>
  );
};

import React from "react";

import { redirectUrlFromSearch } from "lib/search-params";
import { AuthForm } from "./AuthForm";
import { AuthTemplate } from "./templates/AuthTemplate";

const labelMapFromDomain = {
  map: "TalentBoost",
  app: "TalentScore",
};
const loginLabel = (redirectUrl: string | null) => {
  if (redirectUrl?.includes("map")) {
    return labelMapFromDomain.map;
  } else if (redirectUrl?.includes("app")) {
    return labelMapFromDomain.app;
  } else {
    return "";
  }
};

export const LoginView: React.FC = () => {
  return (
    <AuthTemplate title={`Log in to your ${loginLabel(redirectUrlFromSearch())} account.`}>
      <AuthForm />
    </AuthTemplate>
  );
};

import { CrossMark } from "assets/icons";
import React from "react";
import styled from "styled-components";

import { outlineActiveElement } from "ui/style-snippets";

const ClearButton = styled.button`
  display: flex;
  position: absolute;
  right: 12px;
  bottom: 18px;
  transform: translateY(50%);
  background-color: transparent;
  border: none;
  color: ${p => p.theme.textLowContrast};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.primaryHighestContrast};
  }
  &:enabled:focus-visible {
    ${outlineActiveElement}
  }
  svg {
    height: 10px;
  }
`;

interface ClearInputValueButtonProps {
  className?: string;
  title: string;
  onClick: () => void;
}
export const ClearInputValueButton = (props: ClearInputValueButtonProps) => {
  const { className, title, onClick } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <ClearButton
      aria-label={title}
      className={className}
      title={title}
      tabIndex={-1}
      onClick={handleClick}
      type="button"
    >
      <CrossMark />
    </ClearButton>
  );
};

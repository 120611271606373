import React from "react";
import { Navigate } from "react-router";
import styled from "styled-components";

import devSkillerLogo from "assets/images/devskiller-logo.svg";
import talentBoostLogo from "assets/images/talent-boost.svg";
import talentScoreLogo from "assets/images/talent-score.svg";
import { getEmailFromToken, getProductsFromToken } from "lib/decode-token";
import { routes } from "routes";
import { palette } from "styles/palette";
import { srOnly } from "styles/srOnly";
import { useCanAccessRoleManagerView } from "ui/hooks/useCanAccess";
import { CONFIG } from "../env";
import { AuthedAs } from "./AuthedAs";

const MainWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
  background: ${palette.backgroundPrimary};
`;
MainWrapper.displayName = "MainWrapper";

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
`;

const SrOnlyTitle = styled.h1`
  ${srOnly}
`;
SrOnlyTitle.displayName = "SrOnlyTitle";

const WelcomeBackCopy = styled.h2`
  font-size: 16px;
  margin-bottom: 20px;
`;
WelcomeBackCopy.displayName = "WelcomeBackCopy";

const Logo = styled.img`
  height: 40px;
`;
Logo.displayName = "Logo";

const NavigateCopy = styled.p`
  font-size: 16px;
`;
NavigateCopy.displayName = "NavigateCopy";

const Products = styled.section`
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;
Products.displayName = "Products";

const ProductWrapper = styled.div`
  height: 340px;
  width: 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-radius: 4px;
  padding: 15px;
  background: ${palette.backgroundPrimary};
`;
ProductWrapper.displayName = "ProductWrapper";

const ProductLink = styled.a`
  height: 240px;
  width: 240px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 4%);
  transition: box-shadow 0.2s ease;
  background: ${palette.backgroundPrimary};

  &:hover {
    box-shadow: -5px 5px 5px 0 rgb(0 0 0 / 4%);
  }
`;
ProductLink.displayName = "ProductLink";

const ManageViewLink = styled.a`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  margin-top: 15px;
  border-radius: 4px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 4%);
  transition: box-shadow 0.2s ease;
  background: ${palette.backgroundPrimary};
`;
ManageViewLink.displayName = "ManageViewLink";

const ProductLogo = styled.img`
  height: 40px;
`;
ProductLogo.displayName = "ProductLogo";

export const MainView: React.FC = () => {
  const displayRoleManagerView = useCanAccessRoleManagerView();

  if (!getEmailFromToken()) {
    return <Navigate to="/login" />;
  }

  let products = getProductsFromToken();

  // FIXME - should hide product tile if no on products list
  if (CONFIG.TALENT_BOOST_URL && products?.length === 1 && products[0] === "map") {
    window.location.href = CONFIG.TALENT_BOOST_URL;
    return (
      <MainWrapper>
        <ProductWrapper>
          <ProductLink href={CONFIG.TALENT_BOOST_URL}>
            <ProductLogo src={talentBoostLogo} alt="Talent Boost logo" />
          </ProductLink>
        </ProductWrapper>
      </MainWrapper>
    );
  } else if (CONFIG.TALENT_SCORE_URL && products?.length === 1 && products[0] === "skiller") {
    window.location.href = CONFIG.TALENT_SCORE_URL;
    return (
      <MainWrapper>
        <ProductWrapper>
          <ProductLink href={CONFIG.TALENT_SCORE_URL}>
            <ProductLogo src={talentScoreLogo} alt="Talent Score logo" />
          </ProductLink>
        </ProductWrapper>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <Header>
        <SrOnlyTitle>DevSkiller auth lobby.</SrOnlyTitle>
        <WelcomeBackCopy>Welcome back at</WelcomeBackCopy>
        <Logo src={devSkillerLogo} alt="DevSkiller logo" />
      </Header>

      <NavigateCopy>Navigate to one of our products.</NavigateCopy>

      <Products>
        <ProductWrapper>
          <ProductLink href={CONFIG.TALENT_SCORE_URL}>
            <ProductLogo src={talentScoreLogo} alt="Talent Score logo" />
          </ProductLink>
        </ProductWrapper>
        <ProductWrapper>
          <ProductLink href={CONFIG.TALENT_BOOST_URL}>
            <ProductLogo src={talentBoostLogo} alt="Talent Boost logo" />
          </ProductLink>
          {displayRoleManagerView && (
            <ManageViewLink href={routes.MANAGE_USERS}>Manage TalentBoost users</ManageViewLink>
          )}
        </ProductWrapper>
      </Products>
      <AuthedAs />
    </MainWrapper>
  );
};

import styled from "styled-components";

import { palette } from "styles/palette";

export const CodeSnippet = styled.code`
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${palette.grey};
  background: ${palette.greyPale};
  border-radius: 4px;
  display: block;
  padding: 8px 16px;
  overflow-x: auto;
`;
CodeSnippet.displayName = "CodeSnippet";

import axios from "axios";
import React, { createContext } from "react";

import { fakeAssignablePermissions } from "api/_mocks_";
import { CompanyResource, RestResponse } from "auth";
import {
  CreateRoleDefinitionPayload,
  CreateRoleDefinitionResponse,
  GetCompanyRolesResponse,
  GetManagedUsersResponse,
  GetRoleDefinitionResponse,
  GetRolesResponse,
  GetSelfResponse,
  GetTeamsResponse,
  GetUserDetailsResponse,
  GetUsersPayload,
  GetUsersQueryParams,
  UpdateRoleDefinitionPayload,
  UpdateRoleDefinitionResponse,
  UpdateUserRolesPayload,
  UpdateUserRolesResponse,
} from "lib/dto";
import { AssignablePermissions } from "lib/types";

export type RestApiContextValue = {
  getSelf: () => RestResponse<GetSelfResponse>;
  getCompany: (id: string) => Promise<CompanyResource>;
  getTeams: (companyId: string) => GetTeamsResponse;
  getUsers: (
    companyId: string,
    query: GetUsersPayload,
    queryParams?: GetUsersQueryParams,
  ) => GetManagedUsersResponse;
  getUserDetails: (userId: string, companyId: string) => GetUserDetailsResponse;
  updateUserRoles: (
    companyId: string,
    userId: string,
    userRoleMappingUpdate: UpdateUserRolesPayload,
  ) => UpdateUserRolesResponse;

  getRoles: (companyId: string) => GetCompanyRolesResponse;
  getRoleDefinition: (roleId: string, companyId: string) => GetRoleDefinitionResponse;
  updateRole: (
    payload: UpdateRoleDefinitionPayload,
    roleId: string,
    companyId: string,
  ) => UpdateRoleDefinitionResponse;
  createRole: (
    payload: CreateRoleDefinitionPayload,
    companyId: string,
  ) => CreateRoleDefinitionResponse;
  getAssignablePermissions: () => Promise<AssignablePermissions>;
};

export const RestApiContext = createContext<RestApiContextValue | undefined>(undefined);

export const RestApiContextProvider: React.FC = props => {
  const { children } = props;

  const getSelf = async () => {
    const url = "/api/self";
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (e: unknown) {
      throw new Error(`Error while fetching logged in user details.`);
    }
  };

  const getCompany = async (companyId: string) => {
    const url = `/api/companies/${companyId}`;
    try {
      const { data } = await axios.get<CompanyResource>(url);
      return data;
    } catch (e: unknown) {
      throw new Error(`Failed to fetch company with id ${companyId}.`);
    }
  };

  const getTeams = async (companyId: string) => {
    const url = `/api/companies/${companyId}/teams`;
    try {
      const { data } = await axios.get<GetTeamsResponse>(url);
      return data;
    } catch (e: unknown) {
      throw new Error("Error while fetching Teams");
    }
  };

  const getUsers = async (
    companyId: string,
    query: GetUsersPayload,
    queryParams?: GetUsersQueryParams,
  ) => {
    const url = `/api/companies/${companyId}/users`;
    try {
      const { data } = await axios.post<GetManagedUsersResponse>(
        url,
        { email: query.email, teamIds: query.teamIds },
        {
          params: queryParams,
          headers: { "Content-Type": "application/json" },
        },
      );
      return data;
    } catch (e: unknown) {
      throw new Error("Error while fetching ManagedUsers");
    }
  };

  const getUserDetails = async (id: string, companyId: string) => {
    const url = `/api/companies/${companyId}/users/${id}`;
    try {
      const { data } = await axios.get<GetUserDetailsResponse>(url);
      return data;
    } catch (e: unknown) {
      throw new Error(`Error while fetching details for user ${id}`);
    }
  };

  const updateUserRoles = async (
    companyId: string,
    userId: string,
    userRoleMappingUpdate: UpdateUserRolesPayload,
  ) => {
    const url = `/api/companies/${companyId}/users/${userId}/roleDefinitions`;
    try {
      const { data } = await axios.put(url, userRoleMappingUpdate, {
        headers: { "Content-Type": "application/json" },
      });
      return data;
    } catch (e: unknown) {
      throw new Error("Error while updating Roles");
    }
  };

  const getRoles = async (companyId: string) => {
    const url = `/api/companies/${companyId}/roles`;
    try {
      const { data } = await axios.get<GetRolesResponse>(url);
      return data;
    } catch (e: unknown) {
      throw new Error("Error while fetching Roles list");
    }
  };

  const getRoleDefinition = async (roleId: string, companyId: string) => {
    const url = `/api/companies/${companyId}/roles/${roleId}`;
    try {
      const { data } = await axios.get<GetRoleDefinitionResponse>(url);
      return data;
    } catch (e: unknown) {
      throw new Error("Error while fetching Role");
    }
  };

  const createRole = async (payload: CreateRoleDefinitionPayload, companyId: string) => {
    const url = `/api/companies/${companyId}/roles`;
    try {
      const { data } = await axios.post<CreateRoleDefinitionResponse>(url, payload);
      return data;
    } catch (e: unknown) {
      throw new Error("Error while creating role.");
    }
  };

  const updateRole = async (
    payload: UpdateRoleDefinitionPayload,
    roleId: string,
    companyId: string,
  ) => {
    const url = `/api/companies/${companyId}/roles/${roleId}`;
    try {
      const { data } = await axios.put(url, payload);
      return data;
    } catch (e: unknown) {
      throw new Error("Error while updating role");
    }
  };

  // TODO: This should not be a FAKE!
  const getAssignablePermissions_fake = async () => {
    // const url = `TO_BE_FILLED_LATER`;
    try {
      // const { data } = await axios.get<GetRolesResponse>(url);
      const data = fakeAssignablePermissions;
      return data;
    } catch (e: unknown) {
      throw new Error("Error while fetching assignable Permissions");
    }
  };

  return (
    <RestApiContext.Provider
      value={{
        getSelf,
        getCompany,
        getTeams,

        getUsers,
        getUserDetails,
        updateUserRoles,

        getRoles,
        getRoleDefinition,
        updateRole,
        createRole,
        getAssignablePermissions: getAssignablePermissions_fake,
      }}
    >
      {children}
    </RestApiContext.Provider>
  );
};

import React from "react";

import { Svg } from "./styled";
import { IconProps } from "./types";

export const CopyIcon: React.FC<IconProps> = props => {
  const { color, className } = props;
  return (
    <Svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4732 4H5.52679C4.68357 4 4 4.68357 4 5.52679V11.4732C4 12.3164 4.68357 13 5.52679 13H11.4732C12.3164 13 13 12.3164 13 11.4732V5.52679C13 4.68357 12.3164 4 11.4732 4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.8437 4L10.8571 2.5C10.856 2.10252 10.6976 1.72165 10.4166 1.44059C10.1355 1.15953 9.75462 1.00113 9.35714 1H2.71429C2.26004 1.00134 1.82479 1.18239 1.50359 1.50359C1.18239 1.82479 1.00134 2.26004 1 2.71429V9.35714C1.00113 9.75462 1.15953 10.1355 1.44059 10.4166C1.72165 10.6976 2.10252 10.856 2.5 10.8571H4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

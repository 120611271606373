import React from "react";

import devSkillerLogo from "../../assets/images/devskiller-logo.svg";
import styles from "./AuthTemplate.module.scss";

import billenniumLogo from "../../assets/images/logos/billennium-logo.svg";
import canonicalLogo from "../../assets/images/logos/canonical-logo.svg";
import hrpmLogo from "../../assets/images/logos/hrpm-logo.svg";
import justEatLogo from "../../assets/images/logos/just-eat-logo.svg";
import morningstarLogo from "../../assets/images/logos/morningstar-logo.svg";
import orangeLogo from "../../assets/images/logos/orange-logo.svg";
import rocheLogo from "../../assets/images/logos/roche-logo.svg";
import unicefLogo from "../../assets/images/logos/unicef-logo.svg";
import volkswagenLogo from "../../assets/images/logos/volkswagen-logo.svg";

const Heading = (props: { title: string }) => {
  return (
    <>
      <img className={styles.devSkillerLogo} src={devSkillerLogo} alt="DevSkiller logo" />
      <h3 className={styles.subtitle}>{props.title}</h3>
    </>
  );
};
export const AuthTemplate = (props: { title: string; children?: React.ReactNode }) => {
  const showHrpmLogo =
    window.location.hostname.startsWith("hrpm.") || window.location.search.includes("client=hrpm");

  return (
    <>
      <div className={styles.authTemplateBackground}>
        <div className={styles.staticSection}>
          <div className={styles.staticContent}>
            <div className={styles.heading}>
              <Heading title={props.title} />
            </div>
            {showHrpmLogo ? <HrpmLogo /> : <TrustedBy />}
          </div>
        </div>
        <div className={styles.authSection}>
          <div className={styles.mobileHeading}>
            <Heading title={props.title} />
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

const TrustedBy = () => {
  return (
    <>
      <span className={styles.trustedBy}>
        Trusted by customers <strong>from 60+ countries</strong>
      </span>
      <div className={styles.logosGrid}>
        <img src={rocheLogo} alt="Roche logo" />
        <img src={morningstarLogo} alt="Morningstar logo" />
        <img src={billenniumLogo} alt="Billennium logo" />
        <img src={unicefLogo} alt="UNICEF logo" />
        <img src={justEatLogo} alt="Just Eat logo" />
        <img src={canonicalLogo} alt="Canonical logo" />
        <img src={volkswagenLogo} alt="Volkswagen logo" />
        <img src={orangeLogo} alt="Orange logo" />
      </div>
    </>
  );
};

const HrpmLogo = () => {
  return (
    <div style={{ minWidth: "300px" }}>
      <img src={hrpmLogo} alt="HR Power Mentor" />
    </div>
  );
};

import { useLocalStorage } from "./useLocalStorage";

export const useCompanyOverrideStorage = () => {
  const [storageValue, setStorageValue, clear] = useLocalStorage("auth:roleManager:company", "");

  const parseValue = (val: string | undefined) => {
    if (!val) {
      return undefined;
    }
    const _v = val.split(",");
    return { id: _v[0], name: _v[1], override: _v[2] === "true" };
  };

  return [parseValue(storageValue), setStorageValue, clear] as const;
};

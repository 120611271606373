import styled from "styled-components";
import { palette } from "../../styles/palette";

export const ErrorMessage = styled.span`
  color: ${palette.red};
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.2px;
  margin-top: 16px;
  text-align: center;
`;
ErrorMessage.displayName = "ErrorMessage";

import { DefaultTheme } from "styled-components";
import { colors } from "./colors";

export const status: DefaultTheme["status"] = {
  errorHighestContrast: colors.red90,
  errorMediumContrast: colors.red50,
  errorLowestContrast: colors.red10,

  infoHighestContrast: colors.blue60,
  infoMediumContrast: colors.blue30,
  infoLowestContrast: colors.blue10,

  successHighestContrast: colors.green90,
  successHighContrast: colors.green80,
  successMediumContrast: colors.green60,
  successLowestContrast: colors.green10,

  warningHighestContrast: colors.orange90,
  warningMediumContrast: colors.orange20,
  warningLowestContrast: colors.orange10,
};

export const lightTheme = {
  /* Backgrounds */
  bgApp: colors.gray20,
  bgHighestContrast: colors.white,
  bgHighContrast: colors.gray10,
  bgMediumContrast: colors.gray30,

  /* Text colors */
  textHighestContrast: colors.gray90,
  textHighContrast: colors.blue90,
  textMediumContrast: colors.gray80,
  textLowContrast: colors.gray70,
  textLowestContrast: colors.gray40,
  textHighestContrastReversed: colors.white,

  /* Main color */
  primaryHighestContrast: colors.blue70,
  primaryHighContrast: colors.blue60,
  primaryMediumContrast: colors.blue50,
  primaryLowContrast: colors.blue40,
  primaryLowestContrast: colors.blue10,

  /* Error */
  errorHighestContrast: colors.red90,
  errorHighContrast: colors.red50,
  errorLowContrast: colors.red20,
  errorLowestContrast: colors.red10,

  /* Borders */
  borderHighContrast: colors.gray50,
  borderMediumContrast: colors.gray30,

  borderDefault: colors.gray30,
  borderActive: colors.blue20,

  tabActive: colors.purple90,

  status,
};

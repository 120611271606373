import React from "react";
import styled from "styled-components";

import devSkillerLogo from "assets/images/devskiller-logo.svg";
import { palette } from "styles/palette";
import { srOnly } from "styles/srOnly";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: ${palette.backgroundSecondary};
`;
Main.displayName = "Main";

const Panel = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
  background: ${palette.backgroundPrimary};

  @media (min-width: 768px) {
    justify-content: center;
    padding: 50px 60px 70px;
    min-height: unset;
    width: auto;
    border-radius: 8px;
    box-shadow: rgb(74 94 104 / 20%) 0px 4px 12px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
  }
`;

const SrOnlyTitle = styled.h1`
  ${srOnly}
`;
SrOnlyTitle.displayName = "SrOnlyTitle";

const Logo = styled.img`
  height: 26px;

  @media (min-width: 768px) {
    height: 32px;
  }
`;
Logo.displayName = "Logo";

type CenterPanelTemplateProps = {
  title: string;
};
export const CenterPanelTemplate: React.FC<CenterPanelTemplateProps> = props => {
  const { title, children } = props;
  return (
    <Main>
      <Panel>
        <Header>
          <SrOnlyTitle>{title}</SrOnlyTitle>
          <Logo src={devSkillerLogo} alt="DevSkiller logo" />
        </Header>
        {children}
      </Panel>
    </Main>
  );
};

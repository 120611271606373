import { Reducer, useEffect, useReducer } from "react";
import { useLocation } from "react-router";
import { useReplaceSearchParams } from "./useQueryParams";

/**
 * Custom hook that initially reads URL search params and returns them as parsed state.
 * Next on each state change updates the URL params.
 *
 * @param reducer Reducer object defining how the state should change based on action type.
 * @param parseURLParamsToState Function describing how URL params should be parsed into state.
 * @param parseStateToURLParams Function describing how state should be parsed into URL params.
 */
export const useQueryParamsReducer = <S, A>(
  reducer: Reducer<S, A>,
  parseURLParamsToState: (urlParams: URLSearchParams) => S,
  parseStateToURLParams: (state: S) => { name: string; value?: string }[],
) => {
  const { state } = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useReplaceSearchParams();
  const [queryParams, dispatchQueryParam] = useReducer(reducer, {
    ...parseURLParamsToState(urlSearchParams),
    ...state,
  });

  useEffect(() => {
    const defaultParams = new URLSearchParams(
      parseStateToURLParams(parseURLParamsToState(new URLSearchParams()))
        .filter((param): param is { name: string; value: string } => param.value !== undefined)
        .map(({ name, value }) => [name, value]),
    );

    setUrlSearchParams(
      parseStateToURLParams(queryParams).filter(
        ({ name, value }) => urlSearchParams.has(name) || value !== defaultParams.get(name),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return [queryParams, dispatchQueryParam] as const;
};

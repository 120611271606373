import { useContext } from "react";
import { NO_CONTEXT_VALUE_ERROR } from "../utils";
import { RestApiContext, RestApiContextValue } from "./RestApiContext";

export const useRestApi = (): RestApiContextValue => {
  const contextValue = useContext(RestApiContext);

  if (!contextValue) {
    throw new Error(NO_CONTEXT_VALUE_ERROR("RestApiContext", "useRestApi"));
  }

  return contextValue;
};

import React from "react";

import { PasswordSetForm } from "./PasswordSetForm";
import { AuthTemplate } from "./templates/AuthTemplate";

export const PasswordSetView = () => (
  <AuthTemplate title="Reset your password.">
    <PasswordSetForm />
  </AuthTemplate>
);

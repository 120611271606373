import React, { createContext } from "react";

import { isLoggedIn } from "lib/decode-token";
import { GetSelfResponse } from "lib/dto";
import { useSelfQuery } from "ui/queries";

type AuthContextValue = {
  user: GetSelfResponse | undefined;
};
export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

type ProviderProps = {
  children: React.ReactNode;
};
export const AuthContextProvider = (props: ProviderProps) => {
  const { children } = props;
  const selfQuery = useSelfQuery(isLoggedIn());

  return <AuthContext.Provider value={{ user: selfQuery.data }}>{children}</AuthContext.Provider>;
};

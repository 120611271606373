import { useQuery } from "react-query";

import { useRestApi } from "ui/state/restApi";
import { useCompany } from "../state/userContext";
import { queryKeys } from "./queryKeys";

export const useTeamsQuery = () => {
  const { getTeams } = useRestApi();
  const companyOverride = useCompany();

  return useQuery(queryKeys.TEAMS, () => getTeams(companyOverride.id), {
    staleTime: 5 * 60 * 1000,
  });
}

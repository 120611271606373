import { css } from "styled-components";

import { CSSFonts } from "ui/fonts";
import { placeholder } from "ui/style-snippets";
import { HasErrors } from "ui/types";

export const inputWrapperBase = css<HasErrors>`
  border-radius: 4px;
  padding: 0 16px;
  background: ${p => p.theme.bgHighestContrast};
  border: 1px solid
    ${p => (p.hasErrors ? p.theme.errorHighestContrast : p.theme.borderMediumContrast)};
  transition: all 0.2s;

  &:hover {
    border-color: ${p => (p.hasErrors ? p.theme.errorHighestContrast : p.theme.borderHighContrast)};
  }
  &:focus {
    border: 2px solid
      ${p => (p.hasErrors ? p.theme.errorHighestContrast : p.theme.primaryHighestContrast)};
    outline: thin dotted;
    box-shadow: none;
  }
`;

export const inputFontBase = css<HasErrors>`
  color: ${p => (p.hasErrors ? p.theme.errorHighestContrast : p.theme.textHighestContrast)};
  caret-color: ${p =>
    p.hasErrors ? p.theme.errorHighestContrast : p.theme.primaryHighestContrast};
  ${CSSFonts.semiBold_14}
  ${p => placeholder(p.theme.textLowContrast)}
`;

import { AssignablePermissions } from "lib/types";

export const fakeAssignablePermissions: AssignablePermissions = {
  BOOST: {
    analytics: ["comparison", "dashboard", "onboarding", "promotions"],
    careerpaths: ["edit", "read"],
    jobpositions: ["edit", "read"],
    learningmaterials: ["create", "delete", "edit", "list", "view", "vote"],
    mycareer: ["promotions"],
    person: ["careeranalytics", "delete", "invite", "list", "manage", "summary", "view"],
    personroles: ["view"],
    promotionglobalconfig: ["edit"],
    promotionperson: ["read", "edit"],
    skillratings: ["edit", "view", "request"],
    skillset: ["create", "edit"],
    skills: ["edit"],
    support: ["chat"],
    team: ["edit", "list"],
    personfeedback: ["view", "upload", "delete"],
    mailtemplate: ["edit", "view"],
    workexperience: ["approve"],
  },
};

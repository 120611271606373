import React from "react";

import { AppProviders } from "AppProviders";
import { RoutedAuth } from "RoutedAuth";

function App() {
  return (
    <AppProviders>
      <RoutedAuth />
    </AppProviders>
  );
}

export default App;

import queryString from "query-string";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { getAvailableCredentials, sendLoginForm } from "../api/auth";

import { ChangeAccount } from "./ChangeAccount";

import styles from "./AuthForm.module.css";
import btn from "./controls/Button.module.css";
import fg from "./controls/FormGroup.module.css";
import txt from "./controls/Texts.module.css";

import { hasMessage } from "lib/error";
import google from "../assets/images/google.svg";
import { palette } from "../styles/palette";
import { AuthOption } from "./controls/AuthOption";

const ErrorMessage = styled.span`
  color: ${palette.red};
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.2px;
  margin-top: 16px;
  text-align: center;
  display: block;
`;
ErrorMessage.displayName = "ErrorMessage";

// Note: Cannot use visibility: hidden because LastPass autofill does not work with it
const PasswordInputWrapper = styled.div<{ $visible: boolean }>`
  height: ${props => (props.$visible ? "auto" : "0")};
  opacity: ${props => (props.$visible ? "1" : "0")};
`;

export const AuthForm = () => {
  const [searchParams] = useSearchParams();

  //fields
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [ssoUrl, setSsoUrl] = useState("");
  // const [remember, setRemember] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [error, setError] = useState("");

  const [authOption, setAuthOption] = useState("PASSWORD");
  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (searchParams.has("redirect_url")) {
      setRedirectUrl(searchParams.get("redirect_url")!);
    }
  }, [searchParams]);

  // FIXME: ideally this should be executed before AuthForm gets rendered,
  //        to avoid unnecessary flash of UI when user is redirected to SSO
  useEffect(() => {
    if (searchParams.has("email")) {
      getAvailableCredentials(email, extractRedirectUrl(), {
        onPasswordNeeded: () => setShowPassword(true),
        onSsoUrl: url => setSsoUrl(url),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractRedirectUrl() {
    const parsedQueryString = queryString.parse(window.location.search);
    return parsedQueryString.redirect_url as string;
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (showPassword) {
      if (authOption === "PASSWORD_SSO") {
        window.location.href = ssoUrl;
      } else {
        loginWithPassword();
      }
    } else {
      getAvailableCredentials(email, extractRedirectUrl(), {
        onPasswordNeeded: () => {
          setShowPassword(true);
          if (passwordInputRef.current) {
            passwordInputRef.current.focus();
          }
        },
        onSsoUrl: url => setSsoUrl(url),
      });
    }
  }

  const loginWithPassword = async () => {
    try {
      await sendLoginForm(email, password, extractRedirectUrl());
    } catch (e) {
      setError(hasMessage(e) ? e.message : "Login error");
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.loginForm}>
      {!showPassword && (
        <>
          <h2 className={txt.title}>Log in</h2>
          <div className={fg.formGroup}>
            <input
              id="email"
              autoFocus
              required
              type="email"
              placeholder="email"
              autoComplete="username"
              value={email}
              className={fg.formInput}
              onChange={e => {
                setPassword("");
                setEmail(e.target.value);
              }}
            />
            <label className={fg.formLabel} htmlFor="email">
              E-mail
            </label>
          </div>
          {/*<Checkbox
            id="remember-me"
            label="Remember me on this device"
            checked={remember}
            className={styles.rememberMe}
            onChange={() => setRemember(!remember)}
          />*/}
        </>
      )}
      <PasswordInputWrapper $visible={showPassword}>
        <h2 className={txt.title}>Hello again</h2>
        <span className={txt.subtitle}>Enter your password for:</span>
        <ChangeAccount
          email={email}
          onClick={() => {
            setShowPassword(false);
            setError("");
          }}
        />
        {ssoUrl && (
          <>
            <AuthOption
              title="Single Sign-on"
              subtitle="We detected Single Sign-on enabled for this email."
              isSelected={authOption === "PASSWORD_SSO"}
              onSelect={() => setAuthOption("PASSWORD_SSO")}
            />
            <AuthOption
              title="Password"
              subtitle="Enter your password to access a non-SSO workplace."
              isSelected={authOption === "PASSWORD"}
              onSelect={() => setAuthOption("PASSWORD")}
            />
          </>
        )}
        <div className={fg.formGroup}>
          <input
            ref={passwordInputRef}
            id="password"
            autoFocus
            type="password"
            placeholder="password"
            autoComplete="current-password"
            value={password}
            className={fg.formInput}
            onChange={e => setPassword(e.target.value)}
          />
          <label className={fg.formLabel} htmlFor="password">
            Password
          </label>
        </div>

        <a href={`/reset?redirect_url=${extractRedirectUrl()}`}>
          <span className={styles.forgotPassword}>Forgot your password?</span>
        </a>
      </PasswordInputWrapper>

      <button id={showPassword ? "submit" : "next"} type="submit" className={btn.submitButton}>
        {showPassword ? "Log in" : "Next"}
      </button>

      {!showPassword && (
        <>
          <div className={styles.orSeparator}>
            <span className={styles.orSeparatorContent}>or</span>
          </div>
          <button
            className={styles.googleLoginButton}
            type="button"
            onClick={() =>
              (window.location.href = `/oauth2/authorization/google${
                redirectUrl && `?redirect_url=${redirectUrl}`
              }`)
            }
          >
            <img className={styles.googleImg} src={google} alt="google icon" /> Sign in with Google
          </button>
        </>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </form>
  );
};

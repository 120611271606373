import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getInvitationStatus } from "../api/auth";
import txt from "./controls/Texts.module.css";

export const InvitationForm = () => {
  //fields
  const [invitationState, setInvitationState] = useState("");

  const { invitationIdParam } = useParams<{ invitationIdParam: string }>();

  useEffect(() => {
    async function fetchData() {
      const parsedQueryString = queryString.parse(window.location.search);
      const redirectUrl = parsedQueryString.redirect_url as string;
      await getInvitationStatus(invitationIdParam!, redirectUrl).then(r => {
        console.log(r);
        if (r.status === "PASSWORD_SET") {
          window.location.href = "/login?redirect_url=" + r.redirectUrl;
        } else if (r.status === "VALID") {
          window.location.href =
            "/setPassword?invitation=" + invitationIdParam + "&redirect_url=" + r.redirectUrl;
        } else if (r.status === "ALREADY_LOGGED_IN") {
          window.location.href = r.redirectUrl;
        } else if (r.status === "SSO") {
          window.location.href = r.redirectUrl;
        } else {
          setInvitationState(r.status);
        }
      });
    }
    if (invitationIdParam) {
      fetchData();
    }
  }, [invitationIdParam]);

  return (
    <>
      {invitationState === "INVALID" && (
        <section>
          <h2 className={txt.title}>Your invitation is not valid.</h2>
          <span className={txt.paragraph}>
            Please contact with your manager and request new invitation.
          </span>
        </section>
      )}
      {invitationState === "EXPIRED" && (
        <section>
          <h2 className={txt.title}>Your invitation has expired.</h2>
          <span className={txt.subtitle}>
            Please contact with your manager and request new invitation.
          </span>
        </section>
      )}
    </>
  );
};

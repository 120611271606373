import React, { useState } from "react";
import styled from "styled-components";

import { hasMessage } from "lib/error";
import { palette } from "styles/palette";
import { restProvider } from "../api/rest";
import { ButtonLoader } from "./controls/ButtonLoader";
import { ErrorMessage } from "./styled/ErrorMessage";
import { Input } from "./styled/formControls";
import { CenterPanelTemplate } from "./templates/CenterPanelTemplate";

const Copy = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
`;
Copy.displayName = "Copy";

const SuccessInfo = styled.span`
  color: ${palette.mainGreen};
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.2px;
  margin-top: 16px;
  text-align: center;
`;
SuccessInfo.displayName = "SuccessInfo";

const SubmitButton = styled(ButtonLoader)`
  margin-top: 12px;
`;
export const RunAsView: React.FC = () => {
  const [supportCode, setSupportCode] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const { setRunAsCredentials } = restProvider();

  const runAs = async () => {
    try {
      const result = await setRunAsCredentials(supportCode);
      if (result === "OK") {
        setSuccess("Credentials set correctly.");
      } else {
        setError("Cannot set credentials.");
      }
    } catch (e) {
      setError(hasMessage(e) ? e.message : "Error setting credentials");
    }
  };

  return (
    <CenterPanelTemplate title="Run as view.">
      <Copy>Provide support code to set run as credentials</Copy>
      <Input id="" value={supportCode} onChange={setSupportCode} />
      <SubmitButton onClick={runAs}>submit</SubmitButton>
      {success && <SuccessInfo>{success}</SuccessInfo>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CenterPanelTemplate>
  );
};

import { css, keyframes } from "styled-components";

export const animateColorsOnHover = css`
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
`;

export const outlineActiveElement = css`
  outline: 1px dotted ${props => props.theme.textMediumContrast};
`;

export const placeholder = (color: string) => css`
  ::placeholder {
    color: ${color};
    font-weight: 400;
    opacity: 1;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${color};
    font-weight: 400;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${color};
    font-weight: 400;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${color};
    font-weight: 400;
    opacity: 1;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${color};
    font-weight: 400;
    opacity: 1;
  }
`;

//Present for a11y reasons - values as in bootstrap's .sr-only
export const srOnly = `
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
`;

const appearAnimation = keyframes`
  0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
`;

export const useAppear = css`
  animation: ${appearAnimation} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

import { useSearchParams } from "react-router-dom";

/**
 * Replaces query params in URL - history remains clean
 */
export const useReplaceSearchParams = () => {
  const [params, setParams] = useSearchParams();

  const updateParam = (name: string, value?: string) => {
    if (value) {
      params.set(name, value);
    } else {
      params.delete(name);
    }
  };

  const updateParams = (_params: { name: string; value?: string }[]) => {
    _params.forEach(p => updateParam(p.name, p.value));
    setParams(params, { replace: true });
  };

  return [params, updateParams] as const;
};

import { useHasPermission } from "ui/hooks/useHasPermission";

export const useCanAccessRoleManagerView = () => useHasPermission("auth:user");

export const useCanAccessAdminPanel = () => useHasPermission("auth:superadmin");

export const useCanAccessUsersView = () => useHasPermission("auth:user:read");

export const useCanAccessRolesView = () => useHasPermission("auth:role:read");

export const useCanCreateRole = () => useHasPermission("auth:role:create");
export const useCanEditRole = () => useHasPermission("auth:role:edit");

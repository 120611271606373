import { useContext } from "react";
import { NO_CONTEXT_VALUE_ERROR } from "../utils";
import { UserContext } from "./UserContext";

export const useUserContext = () => {
  const contextValue = useContext(UserContext);

  if (!contextValue) {
    throw new Error(NO_CONTEXT_VALUE_ERROR("UserContext", "useUserContext"));
  }

  return contextValue;
}

export const useCompany = () => {
  const contextValue = useContext(UserContext);

  if (!contextValue?.companyOverride) {
    throw new Error(NO_CONTEXT_VALUE_ERROR("UserContext", "useCompany"));
  }

  return contextValue.companyOverride;
}

import { css } from "styled-components";

const fontFamily = `font-family: 'Open Sans', Arial, sans-serif;`;

//SIZE 10
export const semiBold_10 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 10px;
  line-height: 1.6em;
  letter-spacing: 0.2px;
`;
export const SEMIBOLD_10 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 10px;
  line-height: 1.4em;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

//SIZE 12
export const regular_12 = css`
  ${fontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.2px;
`;
export const semiBold_12 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.2px;
`;
export const SEMIBOLD_12 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4em;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

//SIZE 14
export const regular_14 = css`
  ${fontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.2px;
`;
export const semiBold_14 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5em;
  text-transform: none;
`;
export const SEMIBOLD_14 = css`
  ${semiBold_14};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;
export const bold_14 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.4px;
`;
export const BOLD_14 = css`
  ${bold_14};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

//SIZE 16
export const regular_16 = css`
  ${fontFamily};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4em;
`;
export const semiBold_16 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
`;
export const SEMIBOLD_16 = css`
  ${semiBold_16};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;
export const bold_16 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5em;
`;
export const BOLD_16 = css`
  ${bold_16};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

//SIZE 18
export const regular_18 = css`
  ${fontFamily};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4em;
`;
export const semiBold_18 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33em;
  letter-spacing: 0.2px;
`;
export const bold_18 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 18px;
  line-height: 1.33em;
`;

//SIZE 20
export const semiBold_20 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4em;
`;
export const SEMIBOLD_20 = css`
  ${semiBold_20};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

//SIZE 22
export const semiBold_22 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 22px;
  line-height: 1.4em;
`;
export const bold_22 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 22px;
  line-height: 1.27em;
  letter-spacing: -0.2px;
`;

//SIZE 24
export const semiBold_24 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 24px;
  line-height: 1.17em;
`;
export const bold_24 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3em;
  letter-spacing: -0.2px;
`;
export const BOLD_24 = css`
  ${bold_24};
  text-transform: uppercase;
`;

//SIZE 32
export const semiBold_32 = css`
  ${fontFamily};
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25em;
`;
export const bold_32 = css`
  ${fontFamily};
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25em;
  letter-spacing: -0.2px;
`;

import React from "react";

import { PasswordResetForm } from "./PasswordResetForm";
import { AuthTemplate } from "./templates/AuthTemplate";

export const PasswordResetView = () => (
  <AuthTemplate title="Reset your password.">
    <PasswordResetForm />
  </AuthTemplate>
);

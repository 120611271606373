import { clearLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from "lib/storage";
import { useCallback, useEffect, useState } from "react";

/**
 * @template T
 *
 * Read and save the value from localStorage based on item path.
 * @param {string} path Path to localStorage item.
 * @param {T} initialValue Initial Value to save to localStorage item.
 *
 * @returns An array of:
 *   1. value: current localStorage item value
 *   1. writeValue: function to update localStorage item
 *   1. clearValue: function to remove localStorage item
 */
export const useLocalStorage = <T>(path: string, initialValue?: T) => {
  const [value, setValue] = useState(getLocalStorageItem<T>(path) || initialValue);

  const writeValue = useCallback(
    (_value: T) => {
      setValue(_value);
      setLocalStorageItem(path, _value);
    },
    [path],
  );

  // Run only on init to set initialValue if necessary.
  useEffect(() => {
    const storageValue = getLocalStorageItem<T>(path);
    // If storage value IS undefined but initialValue IS NOT - set the LS item with initialValue
    if (storageValue === undefined && initialValue !== undefined) {
      writeValue(initialValue);
    }
  }, [path, initialValue, writeValue]);

  const clearValue = () => {
    setValue(undefined);
    clearLocalStorageItem(path);
  };

  useEffect(() => {
    const handleEvent = () => {
      setValue(getLocalStorageItem<T>(path));
    };
    window.addEventListener("storage", handleEvent);
    return () => {
      window.removeEventListener("storage", handleEvent);
    };
  }, [path]);

  return [value, writeValue, clearValue] as const;
};

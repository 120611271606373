import queryString from "query-string";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { isAxiosError, OnboardingParams, restProvider } from "api/rest";
import linkedInLogo from "assets/images/logos/LI-Logo.png";
import { AuthedAs } from "./AuthedAs";
import { SecondaryButton } from "./controls/Button";
import { ButtonLoader } from "./controls/ButtonLoader";
import { AuthTemplate } from "./templates/AuthTemplate";

const Paragraph = styled.p`
  margin: 30px 10px 0;
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 38px;
    max-width: 550px;
    margin: 0;
    text-align: left;
  }
`;

const StyledLinkedInLogo = styled.img`
  margin-right: 5px;
  height: 26px;
  vertical-align: text-bottom;

  @media (min-width: 768px) {
    height: 32px;
  }
`;
StyledLinkedInLogo.displayName = "StyledLinkedInLogo";

const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 40px;
    max-width: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
Actions.displayName = "Actions";

const ErrorMessage = styled.span`
  color: #f84d38;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
`;
ErrorMessage.displayName = "ErrorMessage";

const RedirectionInfo = styled.span`
  font-size: 16px;
  margin-top: 40px;
`;
RedirectionInfo.displayName = "RedirectionInfo";

const isOnboardingParams = (params: any): params is OnboardingParams => {
  const hasStringHiringContext = typeof params.hiringContext == "string";
  const hasStringExtensionType = typeof params.extensionType == "string";
  const hasStringRedirectUrl = typeof params.redirectUrl == "string";
  const hasStringSignature = typeof params.signature == "string";
  return (
    hasStringHiringContext && hasStringExtensionType && hasStringRedirectUrl && hasStringSignature
  );
};

export const ThirdPartyConnectionView: React.FC = () => {
  const [params, setParams] = useState<OnboardingParams>({
    redirectUrl: "",
    hiringContext: "",
    extensionType: "",
    signature: "",
  });
  const [error, setError] = useState("");
  const { acceptLinkedinConnection } = restProvider();
  const [connectionOK, setConnectionOK] = useState(false);
  const [redirectionInfo, setRedirectionInfo] = useState("");

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search);
    if (isOnboardingParams(parsedQueryString)) {
      setParams(parsedQueryString);
    } else {
      setError(`Incorrect credentials! ${window.location.href}`);
    }
  }, []);

  const handleCancel = () => {
    if (!params.redirectUrl) {
      setError("Could not redirect!");
    } else {
      window.location.href = params.redirectUrl;
    }
  };

  const handleAuthorise = async () => {
    const missingCredentials = Object.values(params).reduce((acc, cur) => {
      return cur === "";
    }, false);
    if (missingCredentials) {
      setError(`Incorrect credentials! ${window.location.href}`);
    } else {
      try {
        await acceptLinkedinConnection(params);
        setConnectionOK(true);
      } catch (e: unknown) {
        if (isAxiosError(e)) {
          setError((e?.response?.data as { errorMessage: string }).errorMessage);
        }
      }
    }
  };

  useEffect(() => {
    let timer = 3;
    if (!connectionOK) {
      return;
    }
    const interval = setInterval(() => {
      if (timer >= 0) {
        setRedirectionInfo(`You will be redirected to LinkedIn in ${timer}`);
        timer = timer - 1;
      } else {
        window.location.href = params.redirectUrl;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [connectionOK, params]);

  return (
    <AuthTemplate title="Authorize accounts connection.">
      <Paragraph>
        <StyledLinkedInLogo src={linkedInLogo} alt="LinkedIn logo" /> Talent Hub account wants to
        connect to your DevSkiller account.
      </Paragraph>
      <AuthedAs />
      <Actions>
        <ButtonLoader onClick={handleAuthorise}>Authorize</ButtonLoader>
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
      </Actions>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <RedirectionInfo>{redirectionInfo}</RedirectionInfo>
    </AuthTemplate>
  );
};

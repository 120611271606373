import { TickIcon } from "assets/icons";
import React from "react";
import styled from "styled-components";
import { animateColorsOnHover, srOnly } from "ui";
import { CSSFonts } from "ui/fonts";

// Native input is hidden from UI but still focusable & maintains keyboard navigation
const NativeInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const VisibleInputWrapper = styled.label<{ $checked: boolean; $disabled: boolean; $size: number }>`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 34px;
  margin: 0;
  cursor: ${p => (p.$disabled ? "not-allowed" : "pointer")};
  ${animateColorsOnHover}

  &::before {
    content: "";
    display: inline-block;
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;
    border: 1px solid
      ${p => (p.$checked ? p.theme.primaryHighContrast : p.theme.borderHighContrast)};
    border-radius: 4px;
    background-color: ${p =>
      p.$checked ? p.theme.primaryHighContrast : p.theme.bgHighestContrast};
    ${animateColorsOnHover}
  }
  ${NativeInput}:focus + & {
    outline: 1px dotted ${p => p.theme.textHighestContrast};
  }
`;

const Label = styled.span<{ $visible: boolean }>`
  ${CSSFonts.SEMIBOLD_12}
  color: ${p => p.theme.textMediumContrast};
  ${p => !p.$visible && srOnly}
  margin-left: 8px;
`;

const CustomCheckMark = styled.span<{ $checked: boolean; $size: number }>`
  position: absolute;
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.textHighestContrastReversed};
  opacity: ${p => (p.$checked ? 1 : 0)};

  svg {
    width: 70%;
  }
`;

// Native input that is absolutely positioned must be wrapped in a relative parent,
// othervise focusing on checkbox may cause unwanted scroll to top.
const CheckboxWrapper = styled.div`
  position: relative;
`;

interface CheckboxProps {
  id: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  label: string;
  labelVisible?: boolean;
  onChange?: (value: boolean) => void;
  size?: number;
  className?: string;
}
export const Checkbox = (props: CheckboxProps) => {
  const {
    id,
    name,
    checked,
    disabled = false,
    label,
    labelVisible = true,
    onChange,
    size = 20,
    className,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.currentTarget.checked);
    }
  };

  return (
    <CheckboxWrapper>
      <NativeInput
        id={id}
        name={name}
        aria-label={label}
        type="checkbox"
        aria-checked={checked}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <VisibleInputWrapper
        className={className}
        htmlFor={id}
        $checked={checked}
        $disabled={disabled}
        $size={size}
      >
        <CustomCheckMark $checked={checked} $size={size}>
          <TickIcon />
        </CustomCheckMark>
        <Label $visible={labelVisible}>{label}</Label>
      </VisibleInputWrapper>
    </CheckboxWrapper>
  );
};

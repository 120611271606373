import { VndErrors } from "../auth";

export const hasMessage = (e: any): e is Error =>
  typeof e.message === "object" && typeof e.message.type === "string";

export function isVndError(obj: unknown): obj is VndErrors {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "logref" in obj &&
    typeof obj.logref === "string" &&
    "message" in obj &&
    typeof obj.message === "string"
  );
}

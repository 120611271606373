import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDebounce } from "use-debounce";

import { GetUsersPayload, GetUsersQueryParams } from "lib/dto";
import { UserRoleMappingUpdate } from "../../auth";
import { useRestApi } from "../state/restApi";
import { useCompany } from "../state/userContext";
import { queryKeys } from "./queryKeys";

export const useSelfQuery = (loggedIn: boolean) => {
  const { getSelf } = useRestApi();

  return useQuery({
    queryKey: [queryKeys.SELF],
    queryFn: getSelf,
    enabled: loggedIn,
    staleTime: Infinity,
  });
};

export const useUsersQuery = (queryParams: GetUsersPayload & GetUsersQueryParams) => {
  const { getUsers } = useRestApi();
  const companyOverride = useCompany();
  const { email, teamIds, roleIds, ...params } = queryParams;
  const payload: GetUsersPayload = {
    email,
    teamIds,
    roleIds,
  };
  return useQuery({
    queryKey: [
      queryKeys.USER_LIST,
      useDebounce(payload.email, 500),
      payload.teamIds,
      queryParams.page,
      queryParams.size,
      queryParams.sort,
    ],
    queryFn: () => getUsers(companyOverride.id, payload, params),
    staleTime: Infinity,
  });
};

export const useUserDetailsQuery = (id: string) => {
  const { getUserDetails } = useRestApi();
  const companyOverride = useCompany();

  return useQuery({
    queryKey: [queryKeys.USER_DETAILS, id],
    queryFn: () => getUserDetails(id, companyOverride.id),
    staleTime: Infinity,
    keepPreviousData: true,
  });
};

export const useUpdateUserRolesMutation = () => {
  const { updateUserRoles } = useRestApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: {
      companyId: string;
      userId: string;
      userRoleMappingUpdate: UserRoleMappingUpdate;
    }) => updateUserRoles(args.companyId, args.userId, args.userRoleMappingUpdate),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([queryKeys.USER_LIST]);
      queryClient.invalidateQueries([queryKeys.USER_DETAILS, variables.userId]);
    },
  });
};

import Axios, { AxiosError } from "axios";

export type OnboardingParams = {
  hiringContext: string;
  extensionType: string;
  redirectUrl: string;
  signature: string;
};

export const isAxiosError = (e: any): e is AxiosError<unknown> => {
  return (e.isAxiosError as boolean) && typeof e.config == "object";
};

export const restProvider = () => {
  const axios = Axios.create({
    baseURL: "/",
  });

  const acceptLinkedinConnection = async (params: OnboardingParams) => {
    const url = "/api/partners/linkedin/onboarding";
    const response = await axios.put(url, params);
    return response.data;
  };

  const setRunAsCredentials = async (supportCode: string) => {
    const url = "/oauth2/token?grant_type=authorization_code&code=" + supportCode;
    try {
      const response = await axios.post(url);
      switch (response.status) {
        case 200:
          return "OK";
        default:
          return "ERROR";
      }
    } catch (e) {
      throw e;
    }
  };

  const generateSupportCode = async () => {
    const url = "/api/users/self/supportCode";
    const response = await axios.post(url);
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error("Code can not be generated. Please contact support.");
    }
  };

  const logout = async () => {
    const url = "/logout";
    try {
      const response = await axios.post(url, null, {
        params: {
          redirect_url: window.location.href,
        },
      });
      if (response.status === 200) {
        window.location = response.headers["location"];
      }
    } catch (e) {
      throw e;
    }
  };

  return {
    acceptLinkedinConnection,
    setRunAsCredentials,
    generateSupportCode,
    logout,
  };
};

import React from "react";
import styled, { css } from "styled-components";

import { HasErrors } from "ui/types";

import { inputFontBase, inputWrapperBase } from "./styles";

const disabledStyles = css`
  filter: grayscale(1);
  background-color: ${p => p.theme.bgMediumContrast};
  border-color: ${p => p.theme.borderMediumContrast};
  color: ${p => p.theme.textMediumContrast};
  cursor: not-allowed;
`;
const StyledInput = styled.input<HasErrors & { disabled: boolean }>`
  ${inputWrapperBase}
  ${inputFontBase}
  height: 40px;
  ${p => p.disabled && disabledStyles}
`;

type TextInputProps = HasErrors & {
  id: string;
  value?: string;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};
export const Input: React.FC<TextInputProps> = props => {
  const { value, onChange, onBlur, disabled, ...other } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e.currentTarget.value);
  };
  return (
    <StyledInput
      {...other}
      disabled={disabled || false}
      value={value || ""}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

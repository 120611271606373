import React from "react";

import person from "../assets/images/person.svg";

import styles from "./ChangeAccount.module.css";

export const ChangeAccount: React.FC<{
  email: string;
  onClick(event: React.SyntheticEvent): void;
}> = props => {
  const { email, onClick } = props;
  return (
    <div className={styles.changeAccount}>
      <div className={styles.account}>
        <img src={person} alt="person" />
        <span className={styles.email}>{email}</span>
      </div>
      <button className={styles.changeButton} onClick={onClick} type="button">
        Change account
      </button>
    </div>
  );
};

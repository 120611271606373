import { useContext } from "react";

import { NO_CONTEXT_VALUE_ERROR } from "../utils";
import { AuthContext } from "./AuthContext";

export const useAuthContext = () => {
  const contextValue = useContext(AuthContext);

  if (!contextValue) {
    throw new Error(NO_CONTEXT_VALUE_ERROR("AuthContext", "useAuthContext"));
  }
  return contextValue;
};

export const useAuthUser = () => {
  const contextValue = useAuthContext();

  if (!contextValue.user) {
    throw new Error(NO_CONTEXT_VALUE_ERROR("AuthContext", "useAuthUser"));
  }
  return contextValue.user;
}

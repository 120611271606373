import { useQuery } from "react-query";

import { useRestApi } from "ui/state/restApi";
import { useCompany } from "ui/state/userContext";
import { queryKeys } from "./queryKeys";

export const useRolesQuery = () => {
  const { getRoles } = useRestApi();
  const companyOverride = useCompany();

  return useQuery({
    queryKey: [queryKeys.ROLE_DEFINITIONS],
    queryFn: () => getRoles(companyOverride.id),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
  });
};

export const useRoleDetailsQuery = (id: string) => {
  const { getRoleDefinition } = useRestApi();
  const companyOverride = useCompany();

  return useQuery({
    queryKey: [queryKeys.ROLE_DEFINITION_DETAILS, id],
    queryFn: () => getRoleDefinition(id, companyOverride.id),
  });
};

export const useAssignablePermissionsQuery = () => {
  const { getAssignablePermissions } = useRestApi();

  return useQuery({
    queryKey: [queryKeys.ASSIGNABLE_PERMISSIONS],
    queryFn: getAssignablePermissions,
  });
};

import styled, { css } from "styled-components";

const buttonPattern = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
`;

export const PrimaryButton = styled.button`
  ${buttonPattern}
  color: #ffffff;
  background-color: #82b801;
  border: 2px solid #82b801;

  &:enabled:hover {
    background-color: #9bc634;
    border: 2px solid #9bc634;
  }
  &:disabled {
    color: #808a94;
    background-color: #d8dee2;
    border: 2px solid #d8dee2;
  }

  &:focus {
    outline: 1px dotted #555a65;
  }
`;

export const SecondaryButton = styled.button`
  ${buttonPattern}
  color: #555A65;
  background-color: transparent;
  border: 2px solid #D8DEE2;

  &:enabled:hover {
    color: #82B801;
  }
  &:disabled {
    color: #D8DEE2;
    border: 2px solid #E6E9EB;
  }
`;

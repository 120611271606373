import React, { PropsWithChildren } from "react";
import { ClearIndicatorProps, components } from "react-select";
import styled from "styled-components";

import { CrossMark } from "assets/icons";
import { SelectOption } from "../types";

const CloseIcon = styled(CrossMark)`
  height: 10px;
  width: auto;
  color: ${p => p.theme.textLowContrast};

  &:hover {
    color: ${p => p.theme.primaryHighContrast};
  }
`;
CloseIcon.displayName = "CloseIcon";

export const ClearIndicator = <OptionValue extends string>(
  props: PropsWithChildren<ClearIndicatorProps<SelectOption<OptionValue>, false>>,
) => (
  <components.ClearIndicator {...props}>
    <CloseIcon />
  </components.ClearIndicator>
);

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { sendLogoutForm } from "api/auth";
import { getEmailFromToken } from "lib/decode-token";
import { hasMessage } from "lib/error";

const AuthAsInfo = styled.p`
  margin-top: 40px;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2px;

  span {
    font-weight: 600;
    color: #82b801;
  }
`;
AuthAsInfo.displayName = "AuthAsInfo";

const LogoutButton = styled.button`
  display: block;
  margin: 0 auto;
  border: none;
  background: transparent;
  text-decoration: underline;

  @media (min-width: 768px) {
    margin: 0;
    display: inline-block;
  }
`;
LogoutButton.displayName = "LogoutButton";

const ErrorMessage = styled.span`
  color: #f84d38;
  font-size: 12px;
  margin-top: 12px;
`;
ErrorMessage.displayName = "ErrorMessage";

export const AuthedAs: React.FC = () => {
  const [authAs, setAuthAs] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setAuthAs(getEmailFromToken());
  }, []);

  const handleLogout = async () => {
    try {
      await sendLogoutForm();
    } catch (e: unknown) {
      setError(hasMessage(e) ? e.message : "Logout error");
    }
  };

  if (!authAs) {
    return null;
  }

  return (
    <>
      <AuthAsInfo>
        You are logged in as <span>{authAs}</span>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </AuthAsInfo>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

import React from "react";

import { InvitationForm } from "./InvitationForm";
import { AuthTemplate } from "./templates/AuthTemplate";

export const InvitationView = () => (
  <AuthTemplate title="Manage your invitation.">
    <InvitationForm />
  </AuthTemplate>
);

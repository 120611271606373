import React, { createContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { isLoggedIn } from "lib/decode-token";
import { useCompanyOverrideStorage } from "ui/hooks/useCompanyOverride";
import { queryKeys, useSelfQuery } from "ui/queries";

type CompanyOverride = { id: string; name: string; override: boolean };

type UserContextValue = {
  companyOverride?: CompanyOverride;
  setCompanyOverride: (data: CompanyOverride) => void;
  resetCompanyOverride: () => void;
};

export const UserContext = createContext<UserContextValue | undefined>(undefined);

export const UserContextProvider: React.FC = props => {
  const { children } = props;

  const [storageCompany, setStorageCompany, clear] = useCompanyOverrideStorage();
  const [selfCompany, setSelfCompany] = useState<CompanyOverride | undefined>(undefined);
  const [companyOverride, setCompanyOverride] = useState<CompanyOverride | undefined>(undefined);

  const selfQuery = useSelfQuery(isLoggedIn());
  const queryClient = useQueryClient();

  const invalidateCompanyQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: [queryKeys.USER_LIST] });
    await queryClient.invalidateQueries({ queryKey: [queryKeys.ROLE_DEFINITIONS] });
    await queryClient.invalidateQueries({ queryKey: [queryKeys.TEAMS] });
  };

  // Check local storage once on init.
  useEffect(() => {
    if (storageCompany) {
      setCompanyOverride(storageCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selfQuery.isFetching && selfQuery.isSuccess) {
      const data = {
        id: selfQuery.data.companyId,
        name: selfQuery.data.companyName,
        override: false,
      };
      setSelfCompany(data);
      // Save self company as overrideCompany only when it wasn't overridden yet.
      if (!storageCompany) {
        overrideCompany(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfQuery.isFetching, selfQuery.isSuccess]);

  const overrideCompany = async (data: CompanyOverride) => {
    setCompanyOverride(data);
    setStorageCompany(`${data.id},${data.name},${data.override}`);
    await invalidateCompanyQueries();
  };

  const resetCompanyOverride = async () => {
    setCompanyOverride(selfCompany);
    if (selfCompany) {
      setStorageCompany(`${selfCompany.id},${selfCompany.name},${selfCompany.override}`);
    } else {
      clear();
    }
    await invalidateCompanyQueries();
  };

  return (
    <UserContext.Provider
      value={{
        companyOverride,
        setCompanyOverride: overrideCompany,
        resetCompanyOverride,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

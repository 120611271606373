export const palette = {
  backgroundPrimary: "#FFFFFF",
  backgroundSecondary: "#f0f2f5",

  navMenuBackground: "#1f292e",
  navMenuItemsColor: "#94949e",
  nacMenuItemActive: "#FFF",

  mainGreen: "#82B801",

  red: "#F84D38",

  greyPale: "#F4F4F4",
  greyMedium: "#D8DEE2",
  grey: "#808A94",
  greyDark: "#555A65",
  dark: "#1D2C34",
};

import React, { useState } from "react";
import styled from "styled-components";

import { hasMessage } from "lib/error";
import { restProvider } from "../api/rest";
import { ButtonLoader } from "./controls/ButtonLoader";
import { CopyCode } from "./styled/CopyCode";
import { ErrorMessage } from "./styled/ErrorMessage";
import { CenterPanelTemplate } from "./templates/CenterPanelTemplate";

const Copy = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
`;
Copy.displayName = "Copy";

const StyledCopyCode = styled(CopyCode)`
  margin-top: 20px;
`;

export const SupportCodeView: React.FC = () => {
  const [supportCode, setSupportCode] = useState("");
  const [error, setError] = useState("");

  const { generateSupportCode } = restProvider();

  const getSupportCode = async () => {
    setError("");
    try {
      const response = await generateSupportCode();
      setSupportCode(response.code);
    } catch (e) {
      setError(hasMessage(e) ? e.message : "Error generating support code");
    }
  };

  return (
    <CenterPanelTemplate title="Generate support code view.">
      <Copy>Generate support code to set run as credentials</Copy>
      <ButtonLoader onClick={getSupportCode}>Generate support code</ButtonLoader>
      {supportCode && <StyledCopyCode content={supportCode} title="Your support code:" />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CenterPanelTemplate>
  );
};

import loadable from "@loadable/component";
import React from "react";
import { Navigate } from "react-router";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { InvitationView } from "components/InvitationView";
import { LoginView } from "components/LoginView";
import { MainView } from "components/MainView";
import { PasswordResetView } from "components/PasswordResetView";
import { PasswordSetView } from "components/PasswordSetView";
import { RunAsView } from "components/RunAsView";
import { SupportCodeView } from "components/SupportCodeView";
import { ThirdPartyConnectionView } from "components/ThirdPartyConnectionView";
import { isLoggedIn } from "lib/decode-token";
import {
  useCanAccessAdminPanel,
  useCanAccessRoleManagerView,
  useCanAccessRolesView,
  useCanAccessUsersView,
} from "ui/hooks/useCanAccess";

const UserPermissionsApp = loadable(() => import("components/userPermissions/UserPermissionsApp"));
const LazyManageUsersView = loadable(
  () => import("components/userPermissions/manageUsers/ManageUsersView"),
);
const LazyUserDetailsView = loadable(
  () => import("components/userPermissions/manageUsers/UserDetails"),
);
const LazyManageRolesView = loadable(
  () => import("components/userPermissions/manageRoles/ManageRolesView"),
);
const LazyRoleDetailsView = loadable(
  () => import("components/userPermissions/manageRoles/RoleDetails"),
);
const LazyCreateRoleView = loadable(
  () => import("components/userPermissions/manageRoles/CreateRole"),
);
const LazyAdminManagementView = loadable(
  () => import("components/userPermissions/adminPanel/AdminManagementView"),
);

export const RoutedAuth = () => {
  const canAccessRoleManagerView = useCanAccessRoleManagerView();
  const canAccessAdminPanel = useCanAccessAdminPanel();
  const canAccessUsersView = useCanAccessUsersView();
  const canAccessRolesView = useCanAccessRolesView();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginView />} />
        <Route path="/reset" element={<PasswordResetView />} />
        <Route path="/setPassword" element={<PasswordSetView />} />
        <Route path="/invitation/:invitationIdParam" element={<InvitationView />} />
        <Route path="/partners/linkedin/onboarding" element={<ThirdPartyConnectionView />} />
        <Route path="/supportCode" element={<SupportCodeView />} />
        <Route path="/runAs" element={<RunAsView />} />
        {canAccessRoleManagerView && (
          <>
            <Route
              path="/manage"
              element={
                <ProtectedRoute>
                  <UserPermissionsApp />
                </ProtectedRoute>
              }
            >
              {canAccessUsersView && (
                <Route path="users" element={<LazyManageUsersView />}>
                  <Route path=":id" element={<LazyUserDetailsView />} />
                </Route>
              )}
              {canAccessRolesView && (
                <Route path="roles" element={<LazyManageRolesView />}>
                  <Route path="create" element={<LazyCreateRoleView />} />
                  <Route path=":id" element={<LazyRoleDetailsView />} />
                </Route>
              )}
              {canAccessAdminPanel && <Route path="admin" element={<LazyAdminManagementView />} />}
            </Route>
          </>
        )}
        <Route path="/" element={<MainView />} />
      </Routes>
    </BrowserRouter>
  );
};

const ProtectedRoute = (props: { children: React.ReactNode }) => {
  // FIXME: this should check user from AuthContext
  if (!isLoggedIn()) {
    return <Navigate to="/login" />;
  }
  return <>{props.children}</>;
};

import React, { PropsWithChildren } from "react";
import { components, DropdownIndicatorProps } from "react-select";
import styled from "styled-components";

import { RightPointingAngleIcon } from "assets/icons";
import { SelectOption } from "../types";

const DropdownIcon = styled(RightPointingAngleIcon)<{ open: boolean }>`
  transform: rotate(${p => (p.open ? "-90deg" : "90deg")});
  transition: transform 0.1s ease-in-out;

  &:hover {
    color: ${p => p.theme.primaryHighContrast};
  }
`;
DropdownIcon.displayName = "DropdownIcon";

export const DropdownIndicator = <OptionValue extends string>(
  props: PropsWithChildren<DropdownIndicatorProps<SelectOption<OptionValue>, false>>,
) => (
  <components.DropdownIndicator {...props}>
    <DropdownIcon open={props.selectProps.menuIsOpen} />
  </components.DropdownIndicator>
);

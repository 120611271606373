import React, { ReactNode } from "react";
import ReactSelect, { ControlProps, DropdownIndicatorProps, OptionProps } from "react-select";
import styled from "styled-components";

import { Label } from "components/styled/formControls/index";
import { Spinner } from "components/styled/Spinner";
import { HasErrors } from "ui";
import { CSSFonts } from "ui/fonts";
import { lightTheme } from "ui/themes";

import { ClearIndicator, DropdownIndicator } from "./customComponents";
import { SelectOption } from "./types";

const SelectWrapper = styled.div`
  position: relative;
  width: 212px;
  ${CSSFonts.semiBold_14}
  cursor: pointer;
`;
SelectWrapper.displayName = "SelectWrapper";

export type SelectProps<OptionValue extends string> = HasErrors & {
  id: string;
  /** Focus the control when it is mounted */
  autoFocus?: boolean;
  /** Sets a className attribute on the  component wrapper */
  className?: string;
  /** Is the select value clearable. Shows clear indicator when true */
  isClearable?: boolean;
  /** Is the select disabled */
  isDisabled?: boolean;
  /** Is the select in loading state (Async) */
  isLoading?: boolean;
  /** Label copy for the select (a11y) */
  label: string;
  /** Should the label be visible in the UI */
  labelVisible?: boolean;
  /** Element to show when no options visible in dropdown */
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  /** Handle change events on the select */
  onChange: (option: SelectOption<OptionValue> | null) => void;
  /** Array of options that populate the select menu */
  options: SelectOption<OptionValue>[];
  /** Custom placeholder copy */
  placeholder?: string;
  /** Is the select required. Renders an asterisk near label if set tu true */
  required?: boolean;
  value?: SelectOption<OptionValue> | null;
};

export function Select<OptionValue extends string>(props: SelectProps<OptionValue>) {
  const {
    id,
    className,
    hasErrors,
    label,
    labelVisible,
    noOptionsMessage = () => "Nothing to select...",
    placeholder = "Select item...",
    required,
    ...other
  } = props;
  const theme = lightTheme;

  return (
    <SelectWrapper className={className} data-testid="select-wrapper">
      <Label inputId={id} label={label} required={required} visible={labelVisible} />
      <ReactSelect
        {...other}
        name={id}
        inputId={id}
        classNamePrefix="Select"
        menuPlacement="auto"
        placeholder={placeholder}
        loadingMessage={() => <Spinner />}
        noOptionsMessage={noOptionsMessage}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
        styles={{
          control: (_, pr: ControlProps<SelectOption<OptionValue>, false>) => ({
            borderRadius: "4px",
            padding: "0 16px",
            background: theme.bgHighestContrast,
            border: `1px solid ${
              pr.selectProps.menuIsOpen ? theme.borderActive : theme.borderDefault
            }`,
            transition: "all 0.2s ease",
            display: "flex",
            height: "40px",
            ":hover": {
              borderColor: theme.borderActive,
              cursor: "text",
            },
            ...(() =>
              hasErrors && {
                borderColor: theme.errorHighestContrast,
                ":hover": {
                  borderColor: theme.errorHighestContrast,
                },
              })(),
          }),
          dropdownIndicator: (
            base,
            pr: DropdownIndicatorProps<SelectOption<OptionValue>, false>,
          ) => ({
            ...base,
            padding: "8px 0 8px 12px",
            cursor: "pointer",
            color: pr.selectProps.menuIsOpen ? theme.primaryHighContrast : theme.textLowContrast,
            ":hover": {
              color: theme.primaryHighestContrast,
            },
          }),
          clearIndicator: base => ({
            ...base,
            cursor: "pointer",
            padding: "12px",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          input: base => ({
            ...base,
            margin: 0,
            padding: 0,
            color: theme.textHighestContrast,
          }),
          menu: base => ({
            ...base,
            background: theme.bgHighestContrast,
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
            border: "none",
            padding: "4px 0",
            marginTop: "4px",
            marginBottom: "4px",
          }),
          option: (base, pr: OptionProps<SelectOption<OptionValue>, false>) => ({
            ...base,
            height: "32px",
            lineHeight: "32px",
            padding: "0 16px",
            color: theme.textHighestContrast,
            backgroundColor: pr.isFocused ? theme.bgMediumContrast : theme.bgHighestContrast,
            whiteSpace: "pre",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            ":hover": {
              backgroundColor: theme.bgMediumContrast,
            },
          }),
          placeholder: base => ({
            ...base,
            color: theme.textLowestContrast,
            margin: 0,
          }),
          singleValue: base => ({
            ...base,
            margin: 0,
            maxWidth: "100%",
            color: hasErrors ? theme.errorHighestContrast : theme.textHighestContrast,
          }),
          valueContainer: base => ({
            ...base,
            padding: 0,
            color: theme.textHighestContrast,
          }),
        }}
      />
    </SelectWrapper>
  );
}

import React from "react";
import styled from "styled-components";

import { palette } from "styles/palette";
import { CodeSnippet } from "./CodeSnippet";
import { CopyToClipboard } from "./CopyToClipboard";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
Header.displayName = "Header";

const Title = styled.h1`
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.2px;
  color: ${palette.dark};
  margin: 0;
`;
Title.displayName = "Title";

type CopyCodeProps = {
  title: string;
  content: string;
  className?: string;
};
export const CopyCode: React.FC<CopyCodeProps> = props => {
  const { title, content, className } = props;
  return (
    <section className={className}>
      <Header>
        <Title>{title}</Title>
        <CopyToClipboard value={content} />
      </Header>
      <CodeSnippet className={`${className}-snippet`}>{content}</CodeSnippet>
    </section>
  );
};

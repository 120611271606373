import { getCookie } from "./get-cookie";
import { parseJwt } from "./parse-jwt";

export const getEmailFromToken = (): string => {
  const token = getCookie("id_token");
  if (token) {
    return parseJwt(token).email;
  } else {
    return "";
  }
};

export const getProductsFromToken = (): string[] | undefined => {
  const token = getCookie("id_token");
  if (token) {
    return parseJwt(token).accessible_products;
  } else {
    return undefined;
  }
};

export const isLoggedIn = () => {
  const idToken = getCookie("id_token");
  return !!idToken;
};

import axios, { AxiosResponse } from "axios";

export const getAvailableCredentials = async (
  email: string,
  redirectUrl: string | undefined,
  { onPasswordNeeded, onSsoUrl }: { onPasswordNeeded(): void; onSsoUrl(url: string): void },
) => {
  let response!: AxiosResponse;
  try {
    response = await axios.get(`/api/credentials/chooser`, {
      params: {
        login: email,
        redirectUrl: redirectUrl,
      },
    });
  } catch (e) {
    // FIXME: Error handling
  }

  if (response.data.authorizationMethodType === "SSO") {
    window.location = response.data.ssoUrl;
  } else if (response.data.authorizationMethodType === "PASSWORD") {
    onPasswordNeeded();
  } else if (response.data.authorizationMethodType === "PASSWORD_SSO") {
    onPasswordNeeded();
    onSsoUrl(response.data.ssoUrl);
  }
};

export const sendLoginForm = async (email: string, pass: string, redirectUrl?: string) => {
  var form = new FormData();
  form.append("username", email);
  form.append("password", pass);
  if (typeof redirectUrl !== "undefined") {
    form.append("redirect_url", redirectUrl);
  }
  try {
    const response = await axios.post("/login", form);

    if (response.status === 200) {
      window.location = response.headers["location"];
    }
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 401) {
      throw new Error("Failure: " + e.response.data.reason);
    } else {
      throw new Error("Login failed");
    }
  }
};

export const sendPasswordResetRequest = async (email: string, redirectUrl?: string) => {
  await axios.post(`/api/credentials/password/reset`, {
    email: email,
    redirect_url: redirectUrl,
  });
};

export const sendPasswordSetRequest = async (
  token: string,
  invitation: string,
  password: string,
) => {
  const params: {
    newPassword: string;
    passwordResetToken?: string;
    invitationToken?: string;
  } = {
    newPassword: password,
  };
  if (typeof token !== "undefined") {
    params.passwordResetToken = token;
  }
  if (typeof invitation !== "undefined") {
    params.invitationToken = invitation;
  }

  await axios.put(`/api/credentials/password/set`, params);
};

export const getInvitationStatus = async (invitationId: string, redirectUrl: string) => {
  let url = `/api/invitations/` + invitationId + "/status";
  if (typeof redirectUrl !== "undefined") {
    url += "?redirect_url=" + redirectUrl;
  }

  const response = await axios.get(url);

  return response.data;
};

export const sendLogoutForm = async () => {
  const logoutForm = document.createElement("form");
  document.body.appendChild(logoutForm);

  logoutForm.action = `/logout?redirect_url=${encodeURIComponent(window.location.href)}`;
  logoutForm.method = "POST";
  logoutForm.submit();

  return new Promise(() => {});
};

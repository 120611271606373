import { VERSION } from "./version";

export const required = (key: string) => {
  if (!process.env[key]) {
    throw new Error(`Environment variable ${key} missing`);
  }
  return process.env[key];
};

const TALENT_SCORE_URL = required("REACT_APP_TALENT_SCORE_URL");
const TALENT_BOOST_URL = required("REACT_APP_TALENT_BOOST_URL");

export const CONFIG = {
  TALENT_BOOST_URL,
  TALENT_SCORE_URL,
  VERSION,
};

declare global {
  interface Window {
    CONFIG: typeof CONFIG;
  }
}
window.CONFIG = CONFIG;

export type EnvironmentName = "LOCAL" | "STAGE" | "PROD";

export const getEnvName = () => {
  const env = process.env.REACT_APP_ENV;
  if (!env) {
    throw new Error(`Invalid env name: "${env}"`);
  }
  return env.toUpperCase() as EnvironmentName;
};

export const isTestEnv = () => process.env.NODE_ENV === "test";

export const isDevEnv = () => getEnvName() !== "PROD";
export const isProdEnv = () => getEnvName() === "PROD";

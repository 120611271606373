import styled, { css, keyframes } from "styled-components";

const rotation = keyframes`
  from {
      transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
const useRotation = css`
  animation: ${rotation} 0.8s linear infinite;
`;

type SpinnerProps = {
  title?: string;
  size?: number;
};
export const Spinner = styled.span<SpinnerProps>`
  display: flex;
  justify-content: center;

  &::before {
    display: block;
    content: "";
    box-sizing: border-box;
    width: ${p => p.size ?? 50}px;
    height: ${p => p.size ?? 50}px;
    border-radius: 50%;
    border: 2px solid ${p => p.theme.primaryHighestContrast};
    border-top-color: ${p => p.theme.primaryLowContrast};
    ${useRotation}
  }
`;
Spinner.displayName = "Spinner";

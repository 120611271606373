export const colors = {
  white: "#FFFFFF",

  gray10: "#F9FAFB",
  gray20: "#F0F2F5",
  gray30: "#E6E9ED",
  gray40: "#C0C4CD",
  gray50: "#A6A6A6",
  gray60: "#94949E",
  gray70: "#818A94",
  gray80: "#555A65",
  gray90: "#131415",

  blue10: "#DAECFF",
  blue20: "#B9D6F2",
  blue30: "#84CAE7",
  blue40: "#02ADFF",
  blue50: "#007AD9",
  blue60: "#1D74F5",
  blue70: "#09619C",
  blue90: "#1D303A",

  red10: "#FEEDEB",
  red20: "#FFB1A5",
  red50: "#F97160",
  red80: "#FC684E",
  red90: "#E8114B",

  purple60: "#9D69A3",
  purple90: "#6941C6",

  green10: "#ECFDF3",
  green60: "#B1E2C5",
  green80: "#57C984",
  green90: "#429964",

  orange10: "#FFFAEB",
  orange20: "#FEF0C7",
  orange80: "#FF7B39",
  orange90: "#F59F00",

  yellow80: "#FFD800",
  yellow90: "#FCAB10",
};

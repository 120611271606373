import React, { useRef } from "react";
import styled from "styled-components";

import { LinkButton } from "components/styled/buttons/LinkButton";
import { srOnly } from "components/styled/css/srOnly";
import { CopyIcon } from "components/styled/icons/CopyIcon";
import { palette } from "styles/palette";

const HiddenContentInput = styled.input`
  ${srOnly}
`;
const CopyButton = styled(LinkButton)`
  display: flex;
  align-items: center;
`;
const StyledCopyIcon = styled(CopyIcon)`
  margin-right: 8px;
`;

export type CopyToClipboardProps = {
  value: string;
};
export const CopyToClipboard: React.FC<CopyToClipboardProps> = props => {
  const { value } = props;
  const contentRef = useRef<HTMLInputElement>(null);
  const copyRef = useRef<HTMLButtonElement>(null);

  const handleCopyToClipBoard = () => {
    if (!navigator.clipboard) {
      if (contentRef && contentRef.current) {
        contentRef.current.select();
        document.execCommand("copy");
        contentRef.current.blur();
      }
      if (copyRef && copyRef.current) {
        copyRef.current.focus();
      }
    } else {
      navigator.clipboard.writeText(value);
    }
  };

  return (
    <>
      <HiddenContentInput
        tabIndex={0}
        ref={contentRef}
        type="text"
        value={value || ""}
        onChange={() => {}}
        aria-hidden={true}
      />
      <CopyButton
        ref={copyRef}
        data-testid="copy-to-clipboard-button"
        onClick={handleCopyToClipBoard}
      >
        <StyledCopyIcon color={palette.mainGreen} />
        Copy
      </CopyButton>
    </>
  );
};

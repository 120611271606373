import React from "react";
import styled from "styled-components";

import { CSSFonts } from "ui/fonts";
import { srOnly } from "ui/style-snippets";

const StyledLabel = styled.label<{ visible: boolean }>`
  margin-bottom: 4px;
  ${CSSFonts.SEMIBOLD_12}
  color: ${p => p.theme.textMediumContrast};
  display: block;
  ${p => !p.visible && srOnly}
`;
StyledLabel.displayName = "Label";

type LabelProps = {
  inputId: string;
  label: string;
  required?: boolean;
  visible?: boolean;
};
export const Label: React.FC<LabelProps> = props => {
  const { inputId, label, required = false, visible = true } = props;
  return (
    <StyledLabel htmlFor={inputId} visible={visible}>
      {label}
      {required && " *"}
    </StyledLabel>
  );
};

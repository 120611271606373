import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import { lightTheme } from "ui";
import { RestApiContextProvider } from "ui/state/restApi";
import { AuthContextProvider } from "ui/state/auth";
import { UserContextProvider } from "ui/state/userContext";

export const AppProviders: React.FC = props => {
  const { children } = props;
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <RestApiContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </RestApiContextProvider>
    </QueryClientProvider>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "./Button";
import { Spinner } from "./Spinner";

export type ButtonLoaderProps = {
  onClick: () => Promise<unknown>;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonLoader: React.FC<ButtonLoaderProps> = props => {
  const { onClick: _onClick, children, ...otherProps } = props;

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // caution: this is a very dirty hack
  // when this component is unmounted (useEffect:cleanup), the promise chain should be cancelled
  // but ES promises don't support cancellation, so we need to either introduce a complex runtime library which would cancel a whole promise chain...
  // or use a simple dirty hack down there
  let isMounted = true;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  const onClick = useCallback(() => {
    setLoading(true);
    // in case a non-async-fn was passed (e.g. spies in tests pass through type checks), the return value is wrapped with a promise
    const promise = Promise.resolve(_onClick());
    promise
      .catch(e => setError(e))
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_onClick]);

  return (
    <PrimaryButton onClick={onClick} {...otherProps}>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        "Error!" // FIXME: handle errors
      ) : (
        <span>{children}</span>
      )}
    </PrimaryButton>
  );
};

export const setLocalStorageItem = <ValueType>(key: string, value: ValueType) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // Failed to execute 'setItem' on 'Storage': Setting the value of '...' exceeded the quota.
    console.warn(e); // eslint-disable-line no-console
  }
};

export const getLocalStorageItem = <ValueType>(key: string) => {
  let storageValue: ValueType | undefined = undefined;
  try {
    const storedItem = localStorage.getItem(key);
    if (storedItem !== null) {
      storageValue = JSON.parse(storedItem);
    }
  } catch (e: unknown) {}
  return storageValue;
};

export const clearLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e: unknown) {}
};

import styled from "styled-components";

import { palette } from "styles/palette";

type LinkButtonProps = {
  className?: string;
};

export const LinkButton = styled.button<LinkButtonProps>`
  font-family: "OpenSans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: 0.2px;
  color: ${palette.mainGreen};
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;

  &::after {
    position: absolute;
    bottom: -2px;
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: ${palette.mainGreen};
    transition: all 0.2s;
  }
  &:hover::after {
    width: 100%;
  }
  &:focus {
    outline: 1px dotted ${palette.greyDark};
  }
`;

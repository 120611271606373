import styled, { css, keyframes } from "styled-components";

const rotation = keyframes`
  from {
      transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
const useRotation = css`
  animation: ${rotation} 0.8s linear infinite;
`;

export const Spinner = styled.span`
  &::before {
    display: block;
    content: "";
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid #f2faea;
    border-top-color: transparent;
    ${useRotation}
  }
`;
